import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { useNavigate, Link } from 'react-router-dom';
import './styles/Calender.css'
import { baseUrl } from './apiHandler';

const CalendarPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [gamesData, setGamesData] = useState({}); // Store games data by date
    const navigate = useNavigate();
    const [completedGames, setCompletedGames] = useState(new Set());
    const [activeMonths, setActiveMonths] = useState(new Set());
    const [activeYears, setActiveYears] = useState(new Set());

    const isDateCompleted = (date) => {
        const dateKey = date.toISOString().split('T')[0];
        const gameId = gamesData[dateKey];
        return completedGames.has(gameId) || completedGames.has(String(gameId));
    };

    const isGameAvailableForDate = (date) => {
        return gamesData[date.toISOString().split('T')[0]]
    }

    const isMonthActive = (date) => {
        return activeMonths.has(date.toISOString().slice(0, 7));
    };

    const isYearActive = (date) => {
        return activeYears.has(date.getFullYear().toString());
    };


    useEffect(() => {
        console.log(`${baseUrl()}/getAllOfficialGames/`)
        const getAllGames = async () => {
            try {
                const response = await fetch(`${baseUrl()}/getAllOfficialGames/`);
                const data = await response.json();
                const newGamesData = {};
                const newActiveMonths = new Set();
                const newActiveYears = new Set();

                data.rows.forEach(row => {
                    const date = new Date(row.date_only);
                    const dateKey = date.toISOString().split('T')[0];
                    newGamesData[dateKey] = row.id;
                    newActiveMonths.add(date.toISOString().slice(0, 7));
                    newActiveYears.add(date.getFullYear().toString());
                });

                setGamesData(newGamesData);
                setActiveMonths(newActiveMonths);
                setActiveYears(newActiveYears);
            } catch (error) {
                console.error('Error fetching grid data:', error);
            }
        };

        const getCompletedGames = async () => {
            const cachedCompletedGames = localStorage.getItem('completedGames');
            if (cachedCompletedGames) {
                setCompletedGames(new Set(JSON.parse(cachedCompletedGames)));
            }
        };

        getCompletedGames();
        getAllGames();
    }, []);

    const onDatePress = async (date) => {
        setSelectedDate(date);
        const formattedDate = date.toISOString().split('T')[0];
        console.log(formattedDate)
        const gameId = gamesData[formattedDate];
        if (gameId) {
            navigate(`/game/${gameId}`);
        }
    };

    return (
        <div>
            <div className='nav-buttons-container'>
                <Link className="generic-button" to="/">Home</Link>
            </div>

            <Calendar
                onChange={onDatePress}
                value={selectedDate}
                maxDetail="month" // This allows users to see the days in a month
                minDetail="year" // This prevents users from seeing the year view when clicking on the year in the month header
                tileDisabled={({ date, view }) => {
                    // Disable tiles not in the current month
                    if (view === 'month' && !isGameAvailableForDate(date)) {
                        return true;
                    }
                    // Disable months and years without games
                    if (view === 'year' && !isMonthActive(date)) {
                        return true;
                    }
                    return false;
                }}
                tileClassName={({ date, view }) => {
                    let classes = [];
                    if (isDateCompleted(date)) {
                        classes.push('completed-date');
                    }
                    return classes.join(' ');
                }}
                tileContent={({ date, view }) =>
                    view === 'month' && isDateCompleted(date) ? (
                        <div className="tileContent">
                            <span className="completed-checkmark">✓</span>
                        </div>
                    ) : null
                }
            />
        </div>
    );
}

export default CalendarPage;
