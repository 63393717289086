import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        const checkGtagFunction = () => {
            return typeof window.gtag === 'function';
        };

        const sendPageView = () => {
            if (checkGtagFunction()) {
                const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
                window.gtag('config', measurementId, {
                    page_path: location.pathname + location.search,
                });
            } else {
                setTimeout(sendPageView, 400); // Retry after 400ms
            }
        };

        sendPageView();
    }, [location]);
};


export default usePageTracking;