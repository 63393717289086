import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from './apiHandler';
import DOMPurify from 'dompurify'
import './styles/CreatePage.css'

const CustomGameCreator = () => {
    const navigate = useNavigate(); // Use useHistory hook for navigation after successful creation
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track if submission is in progress
    const [boardName, setTitle] = useState(''); // State for the game title
    const [author, setAuthor] = useState(''); // State for the author name
    const [boardData, setWords] = useState([
        { category: '', words: ['', '', '', ''] },
        { category: '', words: ['', '', '', ''] },
        { category: '', words: ['', '', '', ''] },
        { category: '', words: ['', '', '', ''] }
    ]);
    const [errors, setErrors] = useState([]);

    const handleInputChange = (groupIndex, wordIndex, event) => {
        const newWords = [...boardData];
        newWords[groupIndex].words[wordIndex] = event.target.value;
        setWords(newWords);
    };

    const handleCategoryChange = (groupIndex, event) => {
        const newWords = [...boardData];
        newWords[groupIndex].category = event.target.value;
        setWords(newWords);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value); // Update the title state
    };

    const handleAuthorChange = (event) => {
        setAuthor(event.target.value); // Update the author state
    };

    const validateInputs = () => {
        let isValid = true;
        let newErrors = []; // Use an array to collect error messages

        if (!boardName.trim()) {
            newErrors.push('Board name is required.');
            isValid = false;
        }

        if (!author.trim()) {
            newErrors.push('Author is required.');
            isValid = false;
        }

        boardData.forEach((group, index) => {
            if (!group.category.trim()) {
                newErrors.push(`Category name for group ${index + 1} is required.`);
                isValid = false;
            }

            group.words.forEach((word, wordIndex) => {
                if (!word.trim()) {
                    newErrors.push(`All words for group ${index + 1}, word ${wordIndex + 1} must be filled.`);
                    isValid = false;
                }
            });
        });

        setErrors(newErrors); // Set the array of errors
        return isValid;
    };

    const confirmClearInputs = () => {
        return window.confirm("Are you sure you want to clear all inputs? This action cannot be undone.");
    };

    const sanitizeInput = (input) => {
        return DOMPurify.sanitize(input);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateInputs() || isSubmitting) {
            return;
        }

        setIsSubmitting(true); // Prevent further submissions

        // Sanitize inputs
        const sanitizedBoardName = sanitizeInput(boardName);
        const sanitizedAuthor = sanitizeInput(author);
        const sanitizedWords = boardData.map(group => ({
            ...group,
            category: sanitizeInput(group.category),
            words: group.words.map(word => sanitizeInput(word))
        }));

        try {

            const response = await fetch(`${baseUrl()}/createCustomGame`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    boardName: sanitizedBoardName,
                    author: sanitizedAuthor,
                    boardData: sanitizedWords
                }),
            });

            const data = await response.json();

            if (response.ok) {
                alert('Game created successfully!');
                navigate('/custom'); // Redirect to custom games page or any other page
            } else {
                alert('Error creating game: ' + data.error);
                setIsSubmitting(false); // Allow retry if submission was not successful
            }

        } catch (error) {
            console.error('Error:', error);
            alert('Error creating game. Please try again later.');
            setIsSubmitting(false); // Allow retry if there was an error
        }
    };

    return (
        <div className='create-page-container'>
            <div className='nav-buttons-container'>
                <Link className="generic-button" to="/">Home</Link>
                <Link className="generic-button" to="/custom">Custom Games</Link>
            </div>
            <div className='error-messages'>
                {errors.map((error, index) => (
                    <p key={index} className='error-message'>{error}</p>
                ))}
            </div>
            <form className="create-page-form" onSubmit={handleSubmit}>
                <div>
                    <label>Game Title: </label>
                    <input
                        type="text"
                        value={boardName}
                        onChange={handleTitleChange}
                        placeholder="Enter a title for the game"
                        maxLength={25} // Adjust the maxLength as needed
                    />
                </div>
                <div>
                    <label>Author: </label>
                    <input
                        type="text"
                        value={author}
                        onChange={handleAuthorChange}
                        placeholder="Enter the author's name"
                        maxLength={20} // Adjust the maxLength as needed
                    />
                </div>
                <div className="categories-container">
                    {boardData.map((group, groupIndex) => {
                        let categoryClass = '';
                        switch (groupIndex) {
                            case 0:
                                categoryClass = 'category-yellow';
                                break;
                            case 1:
                                categoryClass = 'category-green';
                                break;
                            case 2:
                                categoryClass = 'category-blue';
                                break;
                            case 3:
                                categoryClass = 'category-purple';
                                break;
                            default:
                                // Handle more than four categories if needed
                                break;
                        }

                        return (
                            <div className={`${categoryClass}`} key={groupIndex}>
                                <label>Category {groupIndex + 1}: </label>
                                <input
                                    type="text"
                                    value={group.category}
                                    onChange={(event) => handleCategoryChange(groupIndex, event)}
                                    placeholder={`Category ${groupIndex + 1}`}
                                    maxLength={12} // Adjust the maxLength as needed
                                />
                                {group.words.map((word, wordIndex) => (
                                    <div key={wordIndex}>
                                        <label>Word {wordIndex + 1}: </label>
                                        <input
                                            type="text"
                                            value={word}
                                            onChange={(event) => handleInputChange(groupIndex, wordIndex, event)}
                                            placeholder={`Word ${wordIndex + 1}`}
                                            maxLength={12} // Adjust the maxLength as needed
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
                <div className='nav-buttons-container'>
                    <button
                        className="generic-button"
                        type="button"
                        onClick={() => {
                            if (confirmClearInputs()) {
                                // Clear inputs when confirmed
                                setTitle(''); // Clear title
                                setAuthor(''); // Clear author
                                // Clear boardData by creating a new array with empty values
                                setWords(boardData.map(group => ({
                                    category: '',
                                    words: new Array(group.words.length).fill(''),
                                })));
                            }
                        }}
                    >
                        Clear Inputs
                    </button>
                    <button className="generic-button" type="submit">Create Game</button>
                </div>
            </form>
        </div>
    );
}

export default CustomGameCreator;
