import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../styles/ButtonStyles.css'
import { baseUrl } from '../apiHandler';

const MAX_LIVES = 4;


// TODO add something to keep track of if the submission has been done before

class ButtonGrid extends React.Component {

    constructor(props) {
        super(props);
        // Initialize a 2D state array with all values set to false
        const gridState = new Array(4).fill(null).map(() => new Array(4).fill(false));
        const newCompletedButtons = new Array(16).fill(false);
        this.state = {
            buttonToggledStates: gridState,
            toggledCount: 0,
            completedButtons: newCompletedButtons,
            remainingLives: MAX_LIVES,
            incorrectBoxStates: { showIncorrectBox: false, renderInDom: false, boxText: "One away..." }, // Object instead of an array
            isSubmitDisabled: false,
            boardRevealed: false,
            jiggleButtons: [],
            previousGuesses: [],
            timer: 0,
            showTimer: true
        };
        this.boundEventListeners = new WeakMap();
    }

    componentDidMount() {
        if (this.state.showTimer) {
            this.startTimer();
        }
    }

    startTimer = () => {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
        this.timerInterval = setInterval(() => {
            this.setState(prevState => ({ timer: prevState.timer + 1 }));
        }, 1000);
    }

    stopTimer = () => {
        clearInterval(this.timerInterval);
    }

    async waitForAnimation() {

        return new Promise(resolve => {
            const checkInterval = setInterval(() => {
                if (this.allAnimationsDone()) {
                    console.log("Animation Finished!")
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 100); // Check every 100ms
        });
    }

    async markGameAsSuccess(gameId) {
        if (this.state.remainingLives > 0 && !this.state.boardRevealed) {
            try {
                console.log(`Updating database to mark ${gameId} as success`)
                console.log(`${baseUrl()}`)

                fetch(`${baseUrl()}/markGameAsSuccess/${gameId}`)
                    .then(response => {
                        if (!response.ok) { // Check if the response status is not OK (e.g., 404 for not found)
                            throw new Error(`Failed to mark ${gameId} as success`);
                        }
                        return response.json();
                    });

            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    async markGameAsFailed(gameId) {
        try {
            console.log(`Updating database to mark ${gameId} as failed`)
            console.log(`${baseUrl()}`)
            await fetch(`${baseUrl()}/markGameAsFailed/${gameId}`)
                .then(response => {
                    if (!response.ok) { // Check if the response status is not OK (e.g., 404 for not found)
                        throw new Error(`Failed to mark ${gameId} as failed`);
                    }
                    return response.json();
                });

        } catch (error) {
            console.error('Error:', error);
        }
    }

    async waitUntilAllUntoggled() {
        const gameGridDiv = document.querySelector('#GameGrid');

        return new Promise(resolve => {
            const checkInterval = setInterval(() => {
                let buttons = Array.from(gameGridDiv.querySelectorAll('button'));
                if (!buttons.some(button => button.classList.contains('button-highlighted'))) {
                    console.log("All buttons untoggled!")
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 100); // Check every 100ms
        });
    }

    shouldIgnoreUserInput() {
        if (this.allAnimationsDone() !== true || this.state.remainingLives <= 0 || this.state.boardRevealed) {
            return true;
        }
    }

    allAnimationsDone() {
        const gameGridDiv = document.querySelector('#GameGrid');
        const buttons = Array.from(gameGridDiv.querySelectorAll('button'));

        const buttonAnimationsDone = !buttons.some(button =>
            button.classList.contains('button-swapping') &&
            window.getComputedStyle(button).transform !== 'none'
        );
        // Select the disappearing box if it exists
        const disappearingBoxes = document.querySelectorAll('#GameDiv .disappearing-box');

        // Check if there are no disappearing boxes or if they are finished animating
        // Assuming that a class 'animation-done' is added to the box once the animation is completed
        const disappearingBoxAnimationDone = disappearingBoxes.length === 0 ||
            Array.from(disappearingBoxes).every(box => box.classList.contains('animation-done'));


        console.log(`AnimationsDone: ${buttonAnimationsDone}`)
        console.log(buttons)

        // Return true if both button animations and disappearing box animations are done
        return buttonAnimationsDone && disappearingBoxAnimationDone;
    }

    handleGridButtonClick = (row, col) => {
        if (this.shouldIgnoreUserInput()) {
            console.log("Ignoring button click")
            return
        }

        // const { gridData } = this.props;
        console.log(`Grid button clicked at row: ${row}, col: ${col} value: ${this.getButtonValueByRowColId(row, col)}`);
        // const clickedItem = gridData[row][col];
        // console.log(`Value: ${clickedItem}`);

        var newToggleCount = this.state.toggledCount;

        // Toggle the state of the clicked button
        const updatedToggledState = this.state.buttonToggledStates.map((r, rIndex) =>
            r.map((c, cIndex) => {
                if (rIndex === row && cIndex === col) {
                    if (c) // If button was previously toggled
                    {
                        this.setState({ isSubmitDisabled: false });
                        newToggleCount -= 1; // Decrement toggle count
                        return !c; // Toggle state of button
                    }
                    else // If button was previously not selected
                    {
                        // Make sure only 4 items are selected at a time
                        if (newToggleCount < 4) {
                            newToggleCount += 1;
                            return !c
                        }
                        else {
                            console.log("Max of 4 items can be selected at a time")
                            return c;
                        }
                    }
                }
                return c;
            }
            )
        );

        this.setState({ buttonToggledStates: updatedToggledState, toggledCount: newToggleCount });
    }



    getOrderedIndex(index, order) {
        return order[index] - 1;
    }

    getOrderedWord = (index, data, order) => {
        const flatStrings = data.flatMap(group => group.strings);
        return flatStrings[this.getOrderedIndex(index, order)];
    }

    isGameComplete(completedButtons) {
        const isComplete = completedButtons.every(button => button === true);
        if (isComplete) {
            this.stopTimer();
        }
        return isComplete;
    }

    isButtonInArray(button, array) {
        return typeof array.find(e => e === button) !== 'undefined'
    }

    getButtonIdFromButtonObject(button) {
        return button.getAttribute('buttonid')
    }

    getButtonValueByRowColId(row, col) {
        return this.getButtonFromRowColId(row, col).innerText
    }

    getButtonFromRowColId(row, col) {
        return document.querySelector(`[buttonid='${(row * 4) + col}']`)
    }

    getGroupIndexFromButton(btn) {
        return Math.floor(btn.getAttribute('ordered-index') / 4)
    }

    getMaxNumSameGroup(toggledButtons) {
        let groupCount = new Array(4).fill(0)

        toggledButtons.forEach((toggledButton) => {
            groupCount[this.getGroupIndexFromButton(toggledButton)] += 1
        }
        )

        return Math.max(...groupCount)

    }

    async untoggleAllButtons() {
        console.log("Untoggling all buttons")
        let updatedState = [...this.state.buttonToggledStates];

        updatedState = updatedState.map(row => row.map(() => false));

        console.log(updatedState)
        this.setState({ buttonToggledStates: updatedState, toggledCount: 0, isSubmitDisabled: false });
    }

    handleDeselect = async () => {
        if (this.shouldIgnoreUserInput()) {
            console.log("Ignoring button click")
            return
        }

        console.log("Handling deselect")
        this.untoggleAllButtons();

        await this.waitUntilAllUntoggled()
    }

    updateGridRows = (numberOfCompletedRows) => {
        console.log(`Completed Rows ${numberOfCompletedRows}`)
        const gameGridDiv = document.querySelector('#GameGrid');
        let newRowStyle = ""
        for (let i = 0; i < numberOfCompletedRows; i++) {
            newRowStyle += `auto `
        }
        newRowStyle += `repeat(${4 - numberOfCompletedRows}, 1fr)`;
        gameGridDiv.style.gridTemplateRows = newRowStyle;
    }

    handleAnswerCleanup(toggledButtons) {
        console.log("Cleaning up answers")
        const gameGridDiv = document.querySelector('#GameGrid');
        let combinedText = '';
        let updatedState = [...this.state.buttonToggledStates];
        let newCompletedButtons = [...this.state.completedButtons]

        toggledButtons.forEach((button, index) => {
            // Remove any toggled buttons that didn't move. Placing it here so that it doesn't shift buttons around before the swapping animation is complete

            newCompletedButtons[this.getButtonIdFromButtonObject(button)] = true;
            updatedState[button.getAttribute('data-row')][button.getAttribute('data-col')] = false;

            if (index === 0) {
                combinedText += button.innerText;
            }
            else {
                combinedText += `, ${button.innerText}`;
            }
        });

        // Display the large rectangle with combined text
        const rectangle = document.createElement('div');
        rectangle.className = 'answer-box';
        const categoryText = document.createElement('h4');

        // Determine the background color based on the category index
        let bgColor;
        const rootStyle = getComputedStyle(document.documentElement);
        const categoryIndex = this.getGroupIndexFromButton(toggledButtons[0]);
        switch (categoryIndex) {
            case 0:
                bgColor = rootStyle.getPropertyValue('--color-yellow'); // Yellow
                break;
            case 1:
                bgColor = rootStyle.getPropertyValue('--color-green'); // Green
                break;
            case 2:
                bgColor = rootStyle.getPropertyValue('--color-blue'); //  Blue
                break;
            case 3:
                bgColor = rootStyle.getPropertyValue('--color-purple'); // Purple
                break;
            default:
                bgColor = rootStyle.getPropertyValue('--color-default'); // Default background color if none of the above cases match
        }

        // Apply the background color to the rectangle
        rectangle.style.backgroundColor = bgColor;

        categoryText.innerText = this.props.gridData.data[categoryIndex].text
        const categoryWords = document.createElement('p');
        categoryWords.innerText = combinedText

        rectangle.appendChild(categoryText)
        rectangle.appendChild(categoryWords)

        const firstButton = gameGridDiv.querySelector('button');
        if (firstButton) {
            // Insert the rectangle before the first button in the gameGrid div
            gameGridDiv.insertBefore(rectangle, firstButton);
        } else { // To account for the end of the game when no more buttons are present
            gameGridDiv.appendChild(rectangle);
        }

        this.updateGridRows(this.calculateCompletedRows(newCompletedButtons));

        this.setState({ completedButtons: newCompletedButtons, buttonToggledStates: updatedState, toggledCount: 0 });

        if (this.isGameComplete(newCompletedButtons) === true) {
            console.log("game finished")
            this.markGameAsCompleted()
        }
    }

    calculateCompletedRows = (completedButtons) => {
        let completedRowsCount = 0;
        // Iterate over the array in steps of 4
        for (let i = 0; i < completedButtons.length; i++) {
            completedRowsCount += completedButtons[i] ? 1 : 0;
        }

        return completedRowsCount / 4;
    }

    handleShuffle = () => {
        if (this.shouldIgnoreUserInput()) {
            console.log("Ignoring button click")
            return
        }

        // Shuffle by making all buttons go blank and then reset order
        const gameGridDiv = document.querySelector('#GameGrid');

        const allButtonsInGameGrid = gameGridDiv.querySelectorAll('button');
        const buttonArray = Array.from(allButtonsInGameGrid);

        for (let i = buttonArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [buttonArray[i], buttonArray[j]] = [buttonArray[j], buttonArray[i]];
        }

        buttonArray.forEach(button => {
            gameGridDiv.appendChild(button);
        });
    }

    handleReveal = () => {
        if (this.shouldIgnoreUserInput()) {
            console.log("Ignoring button click")
            return
        }

        this.setState({ boardRevealed: true });

        this.handleGameOver();
    }

    markGameAsCompleted = async () => {
        // Retrieve the completed games from localStorage
        const completedGames = JSON.parse(localStorage.getItem('completedGames')) || [];
        const gameId = this.props.gridData.gameId
        console.log(`Setting game completed for ${gameId}`)

        // Check if the gameId is already in the completed games array
        if (!completedGames.includes(gameId)) {
            // If not, add it to the array
            completedGames.push(gameId);

            // Update localStorage with the new array of completed games
            localStorage.setItem('completedGames', JSON.stringify(completedGames));
        }

        this.markGameAsSuccess(gameId);
    };

    isGameCompleted = () => {
        const cachedCompletedGames = new Set(JSON.parse(localStorage.getItem('completedGames')))
        if (cachedCompletedGames) {
            if (cachedCompletedGames.has(this.props.gridData.gameId) || cachedCompletedGames.has(String(this.props.gridData.gameId))) {
                this.setState({ showTimer: false });
                return true
            }
        }
        return false
    }

    handleTransitionEnd(event, fromElem, toggledButtons) {
        const toggledElement = event.target
        console.log("Handle Transition End")
        const swappingElement = fromElem
        console.log(`toggledElement: ${toggledElement.innerText} id: ${toggledElement.getAttribute('buttonid')}`)
        console.log(`swappingElement: ${swappingElement.innerText} id: ${swappingElement.getAttribute('buttonid')}`)
        toggledElement.classList.remove('button-swapping');
        toggledElement.style.transform = '';

        swappingElement.classList.remove('button-swapping');
        swappingElement.style.transform = '';

        const gameGridDiv = document.querySelector('#GameGrid');

        gameGridDiv.insertBefore(swappingElement, toggledElement); // inserting before actually replaces original element. PERFECT!

        if (this.allAnimationsDone() === true) // This will only be the case if this is the last button being called
        {
            console.log("after animation")

            this.handleAnswerCleanup(toggledButtons)
        }

        const boundListener = this.boundEventListeners.get(event.target);
        if (boundListener) {
            event.target.removeEventListener('transitionend', boundListener);
            this.boundEventListeners.delete(event.target);
        }
        else {
            console.log("Warning, failed to remove a listener")
        }

    }

    handleButtonSwaps(toggledButtons) {
        const gameGridDiv = document.querySelector('#GameGrid');

        const firstFourButtonsInGameGrid = Array.from(gameGridDiv.querySelectorAll('button')).slice(0, 4);
        let noTransitions = true // A variable in case all the buttons are already in the top row

        let toSwapIndex = 0; // Using a seperate index so that we can have buttons that don't move if they are in the first row

        // Physically move the buttons 
        toggledButtons.forEach((toggledButton, index) => {
            // console.log("in foreach")
            const fromElem = toggledButton

            if (this.isButtonInArray(fromElem, firstFourButtonsInGameGrid) === false) {
                console.log(`foreach fromButton: ${fromElem.innerText} id: ${fromElem.getAttribute('buttonid')}`)

                let toElem = firstFourButtonsInGameGrid[toSwapIndex];

                // Move button to the next open spot since buttons that are already in the top row do not move.
                while (this.isButtonInArray(toElem, toggledButtons)) {
                    toSwapIndex += 1
                    toElem = firstFourButtonsInGameGrid[toSwapIndex];
                }

                console.log(`foreach toButton: ${toElem.innerText} id: ${toElem.getAttribute('buttonid')}`)

                // Add class tag now so that after transition, button movements are instant
                fromElem.classList.add('button-swapping');
                toElem.classList.add('button-swapping');

                const fromPos = fromElem.getBoundingClientRect();
                const toPos = toElem.getBoundingClientRect();

                // Calculate the translation needed for each button to move to the other's position
                const fromTranslateX = toPos.left - fromPos.left;
                const fromTranslateY = toPos.top - fromPos.top;
                const toTranslateX = fromPos.left - toPos.left;
                const toTranslateY = fromPos.top - toPos.top;

                fromElem.style.transform = `translate(${fromTranslateX}px, ${fromTranslateY}px)`;

                // Don't override the transition if swapping with another toggled button
                if (this.isButtonInArray(toElem, toggledButtons) === false) {
                    toElem.style.transform = `translate(${toTranslateX}px, ${toTranslateY}px)`;
                }
                else {
                    console.log(`Avoiding overriding transition of ${toElem.innerText}`)
                }

                const boundHandleTransitionEnd = (e) => this.handleTransitionEnd(e, toElem, toggledButtons);
                this.boundEventListeners.set(fromElem, boundHandleTransitionEnd);
                fromElem.addEventListener('transitionend', boundHandleTransitionEnd);

                // After transitioning, move the next spot to transition one over
                toSwapIndex += 1
                noTransitions = false

            }
            else {
                console.log(`Stay in place toggledElement: ${fromElem.innerText} id: ${fromElem.getAttribute('buttonid')}`)
                // If the next spot is a button that doesn't move, move the swap index
                if (fromElem === firstFourButtonsInGameGrid[toSwapIndex]) {
                    toSwapIndex += 1
                }
            }

        });

        if (noTransitions) {
            console.log("No transitions, moving on to cleanup")
            this.handleAnswerCleanup(toggledButtons)
        }
    }

    checkIfGuessedAlready = (currentGuess, previousGuesses) => {
        // Convert each guess array to a sorted string based on buttonId for comparison
        const currentGuessStr = currentGuess
            .map(button => parseInt(button.getAttribute('buttonid')))
            .sort((a, b) => a - b) // Sort numbers numerically
            .join(',');

        return previousGuesses.some(previousGuess => {
            const previousGuessStr = previousGuess
                .map(button => parseInt(button.getAttribute('buttonid')))
                .sort((a, b) => a - b) // Sort numbers numerically
                .join(',');
            return currentGuessStr === previousGuessStr;
        });
    };

    triggleOneAwayBox = async (boxText) => {
        // Set showIncorrectBox and renderInDom to true
        this.setState(prevState => ({
            incorrectBoxStates: {
                ...prevState.incorrectBoxStates, // spread the existing incorrectBoxStates
                showIncorrectBox: true,
                renderInDom: true,
                boxText: boxText
            }
        }));


        setTimeout(() => {
            this.setState(prevState => ({
                incorrectBoxStates: {
                    ...prevState.incorrectBoxStates, // spread the existing incorrectBoxStates
                    showIncorrectBox: false
                    // Not updating renderInDom here since we only want to change showIncorrectBox
                }
            }));
        }, 2000);
    }

    handleSubmit = async () => {
        const { buttonToggledStates, previousGuesses } = this.state;

        if (this.shouldIgnoreUserInput()) {
            console.log("Ignoring button click")
            return
        }

        // Find the toggled buttons
        let toggledButtons = [];
        for (let row = 0; row < 4; row++) {
            for (let col = 0; col < 4; col++) {
                if (buttonToggledStates[row][col]) {
                    toggledButtons.push(this.getButtonFromRowColId(row, col));
                }
            }
        }

        // Check if the current guess has already been made
        if (this.checkIfGuessedAlready(toggledButtons, previousGuesses)) {
            this.triggleOneAwayBox("Already Attempted");
            return; // Optionally handle the situation when the guess is not unique
        }

        // Ensure 4 buttons are toggled before proceeding
        if (toggledButtons.length === 4) {
            if (this.getMaxNumSameGroup(toggledButtons) === 4) {
                console.log("ToggledButtons")
                console.log(toggledButtons)
                this.handleButtonSwaps(toggledButtons)
            }

            else if (this.getMaxNumSameGroup(toggledButtons) === 3) {
                this.triggleOneAwayBox("One away...");

                this.handleFailedAttempt(toggledButtons);
            }
            else {
                this.handleFailedAttempt(toggledButtons);
            }

            await this.waitForAnimation();
            this.setState(prevState => ({
                previousGuesses: [...prevState.previousGuesses, toggledButtons]
            }));
        }
    }

    copyGameResult = () => {
        const { previousGuesses } = this.state;
        const { showTimer, timer } = this.state;
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        let resultString = "";

        previousGuesses.forEach(guess => {
            guess.forEach(button => {
                const orderedIndex = parseInt(button.getAttribute('ordered-index'));
                if (orderedIndex >= 0 && orderedIndex <= 3) {
                    resultString += "🟨"; // Yellow
                } else if (orderedIndex >= 4 && orderedIndex <= 7) {
                    resultString += "🟩"; // Green
                } else if (orderedIndex >= 8 && orderedIndex <= 11) {
                    resultString += "🟦"; // Blue
                } else if (orderedIndex >= 12 && orderedIndex <= 15) {
                    resultString += "🟪"; // Purple
                }
            });
            resultString += "\n"; // New line after each guess
        });

        resultString += `Completion Time: ${minutes}:${seconds.toString().padStart(2, '0')}`

        navigator.clipboard.writeText(resultString).then(() => {
            console.log('Result copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    // Utility function for delay
    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    triggleJiggleAnimation = async (jigglingButtonIds, callback) => {
        this.setState({ jiggleButtons: jigglingButtonIds }, async () => {
            // Wait for jiggle to complete
            await this.waitForAnimation()

            // Clear jiggle
            this.setState({ jiggleButtons: [] }, async () => {
                callback();
            });

            // Disable submit while processing
            this.setState({ isSubmitDisabled: true });
        });
    }

    handleFailedAttempt(toggledButtons) {
        console.log("Handling Failed Attempt")

        const jigglingButtonIds = toggledButtons.map(button => `${this.getButtonIdFromButtonObject(button)}`);
        this.setState({ isSubmitDisabled: true });

        this.triggleJiggleAnimation(jigglingButtonIds, () => {
            // Update the remaining lives and proceed if none are left
            this.setState(prevState => ({ remainingLives: prevState.remainingLives - 1 }), async () => {
                if (this.state.remainingLives <= 0) {
                    console.log("No tries remaining!");
                    this.markGameAsFailed(this.props.gridData.gameId);

                    // After both awaits, proceed to handleGameOver
                    this.handleGameOver();
                } else {
                    // Handle other logic for when tries remain
                }
            });
        })
    }

    async handleGameOver() {
        console.log("Handling game over")
        // Handle deselect and wait for all buttons to untoggle
        await this.waitForAnimation()
        await this.untoggleAllButtons();
        await this.waitUntilAllUntoggled();

        const gameGridDiv = document.querySelector('#GameGrid');
        const remainingButtons = Array.from(gameGridDiv.querySelectorAll('button'));
        let remainingCategories = []

        // Get categories of remaining buttons
        remainingButtons.forEach(button => {
            const orderedIndex = parseInt(button.getAttribute('ordered-index'));
            const categoryIndex = Math.floor(orderedIndex / 4);
            if (!remainingCategories.includes(categoryIndex)) {
                remainingCategories.push(categoryIndex);
            }
        });

        const processCategory = async (categories) => {
            if (categories.length === 0) return; // Exit if no more categories left

            const currentCategory = categories.shift(); // Get the current category to be processed
            let updatedButtonStates = [...this.state.buttonToggledStates];

            // Get the first 4 buttons from this category
            const buttonsToToggle = remainingButtons.filter(button => {
                const orderedIndex = parseInt(button.getAttribute('ordered-index'));
                return Math.floor(orderedIndex / 4) === currentCategory;
            }).slice(0, 4);

            buttonsToToggle.forEach(button => {
                const row = parseInt(button.getAttribute('data-row'));
                const col = parseInt(button.getAttribute('data-col'));
                updatedButtonStates[row][col] = true; // Toggle the button
            });

            // Update the state and wait for the state update to complete before continuing
            this.setState({ buttonToggledStates: updatedButtonStates }, () => {
                // Once the setState callback is called, we can be sure the state has been updated
                setTimeout(async () => {
                    // Your code after state has been updated
                    console.log("Before button Swaps");
                    this.handleButtonSwaps(buttonsToToggle);
                    console.log("Waiting for animation complete");

                    await this.waitForAnimation();

                    setTimeout(() => {
                        console.log("Moving on");
                        processCategory(categories);
                    }, 1000);
                }, 1000);
            });
        };

        processCategory(remainingCategories); // Start processing the categories

    }


    render() {
        const { showTimer, timer } = this.state;
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        const { gridData } = this.props;
        const { incorrectBoxStates, buttonToggledStates, completedButtons, isSubmitDisabled } = this.state;

        const boxVariants = {
            visible: { opacity: 1, zIndex: 10 },
            hidden: { opacity: 0, zIndex: 10, transition: { duration: 1 } }
        };

        // Define motion variants for the jiggle effect
        const jiggleDuration = 0.3
        const jiggleAnimation = {
            x: [0, -40, 40, -40, 0],
            transition: {
                x: { duration: jiggleDuration }
            }
            // transition: { duration: 0.3 }
        };

        return (
            <div id="GameDiv" style={{ position: 'relative' }}> {/* A container with relative position */}
                <div>
                    {showTimer && (
                        <div id="TimerDiv">
                            <p>{minutes}:{seconds.toString().padStart(2, '0')}</p>
                        </div>
                    )}
                    {/* ... rest of your render method */}
                </div>
                {incorrectBoxStates.renderInDom && (
                    <motion.div
                        className="disappearing-box"
                        initial="visible"
                        animate={incorrectBoxStates.showIncorrectBox ? "visible" : "hidden"}
                        variants={boxVariants}
                        onAnimationComplete={() => {
                            if (!incorrectBoxStates.showIncorrectBox) {
                                this.setState(prevState => ({
                                    incorrectBoxStates: {
                                        ...prevState.incorrectBoxStates, // Spread only the incorrectBoxStates part of the state
                                        showIncorrectBox: false,
                                        renderInDom: false
                                    }
                                }));
                            }
                        }}
                    >
                        {incorrectBoxStates.boxText}
                    </motion.div>
                )}
                {/* 4x4 Grid */}
                <div id="GameGrid" className="gameGrid" style={{}}>
                    {gridData.data.flatMap(group => group.strings).map((item, flatIndex) => {
                        // Don't render buttons that have been completed
                        if (completedButtons[flatIndex])
                            return null;

                        const rowIndex = Math.floor(flatIndex / 4);
                        const colIndex = flatIndex % 4;
                        return (
                            <motion.button
                                key={flatIndex}
                                buttonid={flatIndex}
                                ordered-index={this.getOrderedIndex(flatIndex, gridData.order)}
                                data-row={rowIndex}
                                data-col={colIndex}
                                className={buttonToggledStates[rowIndex][colIndex] ? 'button-highlighted' : ''}
                                onClick={() => this.handleGridButtonClick(rowIndex, colIndex)}
                                initial={{ x: 0 }}
                                animate={this.state.jiggleButtons.includes(`${flatIndex}`) ? jiggleAnimation : {}}
                            >
                                {this.getOrderedWord(flatIndex, gridData.data, gridData.order)}
                            </motion.button>
                        );
                    })}
                </div>
                <div id="TriesDiv">
                    <p>Tries remaining:
                        {Array.from({ length: MAX_LIVES }, (_, i) => (
                            <span key={i} className={`dot ${i < this.state.remainingLives ? "visible" : "invisible"}`}>&#8226;</span>
                        ))}
                    </p>
                </div>
                {/* Three buttons below the grid */}
                <div className="buttons-container">
                    {!this.isGameComplete(completedButtons) ? (
                        <>
                            <button className="button-under-grid" onClick={this.handleShuffle}>Shuffle</button>
                            <button className="button-under-grid" onClick={this.handleDeselect}>Reset</button>
                            <button className="button-under-grid" onClick={this.handleSubmit} disabled={isSubmitDisabled}>Submit</button>
                        </>
                    ) : (
                        <button className="button-under-grid" onClick={this.copyGameResult}>Copy Result</button>
                    )}
                </div>


            </div >
        );
    }
}

export default ButtonGrid;
