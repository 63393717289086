import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'; // import useParams hook
import ButtonGrid from './components/ButtonGrid';
import './styles/GamesPage.css'
import { baseUrl } from './apiHandler';

const GamesPage = () => {
    const [gridData, setGridData] = useState(null);
    const { gameId } = useParams(); // get the gameId from the route parameter
    const navigate = useNavigate();

    useEffect(() => {
        const apiUrl = typeof gameId === 'undefined'
            ? `${baseUrl()}/getLatestOfficialGame`
            : `${baseUrl()}/getGame/${gameId}`;  // Assuming this is the endpoint for the latest game

        // Fetch data from the server when the component mounts
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) { // Check if the response status is not OK (e.g., 404 for not found)
                    throw new Error('Game not found');
                }
                return response.json();
            })
            .then(data => {
                setGridData(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching grid data:', error);
                if (error.message === 'Game not found') {
                    navigate('/game-not-found');  // Redirect to the game not found page
                }
            });
    }, [gameId, navigate]);  // The empty dependency array means this useEffect runs once when the component mounts

    // I'll make it so that words group 1 is always the easiest while words group 4 is the toughest
    // const gridData = {
    //     data: [
    //         {
    //             text: "Words Group 1",
    //             strings: ["apple", "banana", "cherry", "date"]
    //         },
    //         {
    //             text: "Words Group 2",
    //             strings: ["elephant", "falcon", "giraffe", "hedgehog"]
    //         },
    //         {
    //             text: "Words Group 3",
    //             strings: ["iguana", "jellyfish", "kangaroo", "lemur"]
    //         },
    //         {
    //             text: "Words Group 4",
    //             strings: ["mongoose", "newt", "octopus", "penguin"]
    //         }
    //     ],
    //     order: [3, 16, 8, 12, 1, 4, 11, 15, 10, 2, 14, 9, 6, 7, 13, 5]
    //     // order: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    // };

    // console.log(gridData);

    // Don't render the ButtonGrid until gridData is available
    if (!gridData) {
        return <div>Loading...</div>;
    }

    return (
        <div id="GamePageDiv">
            <div id="GameNavBar">
                <Link className="generic-button" to="/">Home</Link>
                {(gridData.author === "Official NYT Board" || gridData.author === "N/A") && (
                    <Link className="generic-button" to="/archive">Archive</Link>
                )}
            </div>
            <div id="TitleBar">
                <h2>{gridData.title}</h2>
                {(gridData.author !== "Official NYT Board" && gridData.author !== "N/A") && (
                    <h3>By: {gridData.author}</h3>
                )}
            </div>
            <ButtonGrid gridData={gridData} />
        </div >

    );
}

export default GamesPage;
