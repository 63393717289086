// Head.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => {
    return (
        <Helmet>
            {/* Other head tags */}
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`}></script>
            <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){ dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${process.env.REACT_APP_GA_MEASUREMENT_ID}', {
            page_path: window.location.pathname + window.location.search,
          });
        `}
            </script>
        </Helmet>
    );
};

export default Head;
