import { useEffect, useState } from 'react'; import { Link } from 'react-router-dom'
import './styles/Home.css'
import CALogo from "../icons/CALogo.ico"

const Home = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');

    useEffect(() => {
        // Apply the dark mode based on the state when component mounts
        document.body.classList.toggle('dark-mode', darkMode);
    }, [darkMode]); // This effect will run when the darkMode state changes


    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode); // Update the state
        localStorage.setItem('darkMode', newDarkMode); // Save preference in localStorage
        document.body.classList.toggle('dark-mode', newDarkMode); // Apply the class
    };


    return (
        <div>
            <div id="titleBar">
                <img src={CALogo} alt="logo" width={64} height={64} />
                <div id="words">
                    <h1>Connections Archive</h1>
                    <h3>By: Donald Ngai</h3>
                </div>
            </div>

            <div className='nav-buttons-container'>

                <li>
                    <Link className='generic-button' to="/game">Latest</Link>
                </li>
                <li>
                    <Link className='generic-button' to="/archive">Archive</Link>
                </li>
                <li>
                    <Link className='generic-button' to="/custom">Custom</Link>
                </li>
            </div>
            <label className="switch">
                <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default Home;