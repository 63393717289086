import { BrowserRouter as Route } from 'react-router-dom'

const NotFoundPage = () => {
    return (
        <div>
            <p>how are you here?!</p>
        </div>
    )
}

export default NotFoundPage;