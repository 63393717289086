import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoute } from './Routes';
import Head from './Head';

function App() {

  return (
    <div className="App">
      <Head />
      <Router>
        <AppRoute />
      </Router>
    </div>
  );
}

export default App;
