import { Routes, Route } from 'react-router-dom'
import LandingPage from './pages/Home'
import GamesPage from './pages/GamesPage'
import ArchivesPage from './pages/ArchiveCalender'
import CreatePage from './pages/CreatePage'
import CustomGamesPage from './pages/CustomGamesPage'
import NotFoundPage from './pages/NotFoundPage'
import usePageTracking from './usePageTracking';

export const AppRoute = () => {
    usePageTracking(); // This will initialize GA and track pages

    return (
        <Routes>

            <Route index element={<LandingPage />} />
            <Route path="/archive" element={<ArchivesPage />} />
            <Route path="/create" element={<CreatePage />} />
            <Route path="/custom" element={<CustomGamesPage />} />
            <Route path="/game/:gameId?" element={<GamesPage />} />
            <Route path="*" element={<NotFoundPage />} />

        </Routes>
    )
}