import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/CustomGamesPage.css'
import { baseUrl } from './apiHandler';
import CuratedLogo from "../icons/CuratedLogo.png"
import CuratedLogoLight from "../icons/CuratedLogo-Light.png"

const CustomGamesPage = () => {
    const [customGameBoards, setCustomGameBoards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12); // Set the number of items per page
    const darkMode = localStorage.getItem('darkMode') === 'true';
    const cachedCompletedGames = JSON.parse(localStorage.getItem('completedGames'));

    const isGameCompleted = (gameId) => {
        // console.log(cachedCompletedGames)
        if (cachedCompletedGames) {
            const completedGames = new Set(cachedCompletedGames)
            return completedGames.has(gameId) || completedGames.has(String(gameId));
        }
        return false;
    };

    const GameBoardItem = ({ gameBoard }) => {
        // console.log(gameBoard)
        // console.log(gameBoard.board_name)

        if (!gameBoard || !gameBoard.board_name) {
            // Handle the case when gameBoard is undefined or missing board_name
            return null; // or display an error message
        }

        return (
            <Link to={`/game/${gameBoard.id}`} className="game-board-link">
                <div className="game-board-item">
                    <div className='game-board-title'>
                        <h3>
                            {`${gameBoard.board_name}`}
                            {isGameCompleted(gameBoard.id) && (
                                <span className="completed-checkmark">✓</span>
                            )}
                            {gameBoard.curated && (
                                <img
                                    src={darkMode ? CuratedLogoLight : CuratedLogo}
                                    className='curated-logo'
                                    alt="curatedLogo"
                                    width={20}
                                    height={20}
                                />
                            )}
                        </h3>
                        <h4>{`By: ${gameBoard.author}`}</h4>

                    </div>
                    <p className='game-board-stats'> Pass/Fail: {gameBoard.num_success}/{gameBoard.num_failed}</p>
                    <p className='game-board-stats'> Attempts: {gameBoard.num_attempts}</p>
                </div>
            </Link>
        );
    };

    const handleBoardsData = (gameBoards) => {
        // console.log(gameBoards)
        // Check if the gameBoards array is empty
        if (gameBoards.length === 0) {
            console.log("No custom game boards found");
            setCustomGameBoards([]); // Clear any existing game boards
            return;
        }

        // Sort the game boards by date_created in descending order (newest to oldest)
        const sortedGameBoards = gameBoards.sort((a, b) => {
            const dateA = new Date(a.date_created);
            const dateB = new Date(b.date_created);
            return dateB - dateA;
        });

        // Sort the game boards by curated status first and then by date_created in descending order
        // const sortedGameBoards = gameBoards.sort((a, b) => {
        //     // Prioritize curated games
        //     if (a.curated && !b.curated) return -1;
        //     if (!a.curated && b.curated) return 1;

        //     // If both have the same curated status, then sort by date
        //     const dateA = new Date(a.date_created);
        //     const dateB = new Date(b.date_created);
        //     return dateB - dateA;
        // });


        // Limit the number of game boards to the first 120
        const limitedGameBoards = sortedGameBoards.slice(0, 120);

        // console.log(limitedGameBoards);
        setCustomGameBoards(limitedGameBoards);  // Set the fetched game boards to state
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = customGameBoards.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(customGameBoards.length / itemsPerPage);

    // Function to change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        // Fetch the custom game boards from your backend
        const fetchCustomGameBoards = async () => {
            await
                fetch(`${baseUrl()}/getNonOfficialGames/30`)
                    .then(response => response.json())
                    .then(data => {
                        handleBoardsData(data)
                    })
                    .catch(error => {
                        console.error('Error fetching custom games:', error);
                    });
        };


        fetchCustomGameBoards();
    }, []);

    return (
        <div className="custom-game-boards-page">
            <h1>Custom Game Boards</h1>
            <div className='nav-buttons-container'>
                <Link className="generic-button" to="/">Home</Link>
                <Link className="generic-button" to="/create">Create Game</Link>
            </div>
            <div className="game-boards-container">
                {currentItems.map((gameBoard, index) => (
                    <GameBoardItem key={index} gameBoard={gameBoard} />
                ))}
            </div>
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                    <button className="generic-button page-button" key={i + 1} onClick={() => paginate(i + 1)}>
                        {i + 1}
                    </button>
                ))}
            </div>
            <div className='legend'>
                <p><span className="completed-checkmark">✓</span>
                    : Completed Games
                </p>
                <p><img
                    src={darkMode ? CuratedLogoLight : CuratedLogo}
                    className='curated-logo'
                    alt="curatedLogo"
                    width={20}
                    height={20} />
                    : Donalds' Curated </p>
                <p>I love your custom games! And I'm adding a star to the ones I especially liked!</p>
            </div>
        </div>
    );
}

export default CustomGamesPage;
